<template>
  <div class="user">
    <v-card>
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Busqueda"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.$store.state.categories.categories.data"
        :search="search"
        hide-default-footer
        :loading="this.$store.state.categories.isLoading"
        loading-text="Cargando... Por favor espere"
        no-data-text="No hay registro"
        no-results-text="Lo sentimos no hay registro para su busqueda, intente con otro"
      >
        <template v-slot:item.status="{ item }">
          <v-chip
            class="ma-2"
            :color="getStatusColor(item.status)"
            text-color="white"
            small
          >
            {{ getStatusLan(item.status) }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="edit(item)"
                class="px-1"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                :color="item.status == 'ACTIVE' ? 'red' : 'success'"
                dark
                v-bind="attrs"
                v-on="on"
                class="px-1"
                @click="changeStatus(item)"
              >
                {{
                  item.status == "ACTIVE" ? "mdi-lock" : "mdi-lock-open-variant"
                }}
              </v-icon>
            </template>
            <span>
              {{ item.status == "ACTIVE" ? "INACTIVAR" : "ACTIVAR" }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-col cols="12" sm="12" md="12">
        <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="8"
          circle
          @input="nextPage"
        ></v-pagination>
      </v-col>
    </v-card>
    <!-- Button active dialog -->
    <v-btn
      class="mx-2"
      fab
      dark
      bottom
      absolute
      right
      color="secondary"
      @click="open"
    >
      <v-icon dark> mdi-plus </v-icon>
    </v-btn>
    <!-- Create or edit dialog -->
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline">
          {{ currentCategory ? "Editar" : "Crear" }} categoria
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="py-4">
          <v-form ref="formCategory" v-model="validForm" lazy-validation>
            <v-text-field
              v-model="model.name"
              :rules="rules.name"
              label="Nombre"
              required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!validForm"
            color="success"
            class="mr-4"
            small
            @click="save"
          >
            Guardar
          </v-btn>

          <v-btn color="error" text class="mr-4" small @click="clear">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- change status dialog -->
    <v-dialog v-model="dialogChangeStatus" width="500" persistent>
      <v-card>
        <v-card-text class="py-4" v-if="dialogChangeStatus">
          Seguro que desea
          <b>{{
            currentCategory.status == "ACTIVE" ? "INACTIVAR" : "ACTIVAR"
          }}</b>
          esta categoria?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            class="mr-4"
            @click="changeStatusConfirmation"
            small
          >
            Confirmar
          </v-btn>

          <v-btn color="error" text class="mr-4" @click="clear" small>
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SET_CATEGORIES } from "@/store/categories";
import { mapGetters } from "vuex";
export default {
  name: "Users",
  data: () => ({
    search: "",
    headers: [
      {
        text: "Nombre",
        align: "start",
        filterable: true,
        value: "name",
      },
      { text: "Estado", value: "status", filterable: false, sortable: false },
      {
        text: "Acciones",
        value: "actions",
        filterable: false,
        sortable: false,
      },
    ],
    pagination: {
      current: 0,
      total: 0,
    },
    dialog: false,
    dialogChangeStatus: false,
    validForm: true,
    currentCategory: null,
    model: {
      name: "",
    },
    rules: {
      name: [(v) => !!v || "EL nombre es requerido"],
    },
  }),
  created() {
    this.$store.dispatch("getCategories");
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === SET_CATEGORIES) {
        this.pagination.current = state.categories.categories.current_page;
        this.pagination.total = state.categories.categories.last_page;
      }
    });
  },
  watch: {
    dialog: function () {
      if (this.dialog == false) this.clear();
    },
  },
  computed: {
    ...mapGetters(["getStatusLan", "getStatusColor"]),
  },
  methods: {
    nextPage() {
      this.$store.dispatch("nextPageCategories", this.pagination.current);
    },
    save() {
      if (!this.$refs.formCategory.validate()) return;

      if (this.currentCategory) {
        this.$store.dispatch("updateCategory", {
          form: this.model,
          id: this.currentCategory.id,
        });
      } else {
        this.$store.dispatch("createCategory", this.model);
      }
      this.clear();
    },
    changeStatus(user) {
      this.currentCategory = user;
      this.dialogChangeStatus = true;
    },
    changeStatusConfirmation() {
      this.$store.dispatch("updateCategory", {
        form: {
          status:
            this.currentCategory.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
        },
        id: this.currentCategory.id,
      });
      this.clear();
    },
    open() {
      this.dialog = true;
    },
    edit(user) {
      this.currentCategory = user;
      this.model = {
        name: user.name,
      };
      this.open();
    },
    clear() {
      this.dialog = false;
      this.dialogChangeStatus = false;
      if (this.model.name !== "") this.$refs.formCategory.resetValidation();
      this.model = {
        name: "",
      };
      this.currentCategory = null;
    },
  },
};
</script>
<style scoped>
.user {
  height: 100%;
}
.v-btn--bottom {
  bottom: 11px !important;
}
</style>
